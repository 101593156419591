<template></template>

<script>
import { mapState } from 'vuex'
export default {
  mounted: function() {
    let role = "none";
    if(this.has_extended_session && this.extended_session_role != null){
      role = this.extended_session_role;
    }
    else if(this.isLogged === true && this.user != null){
      role = this.user.role;
    }
    if(role !== "none"){
      if(role === "client"){
        this.$router.replace('solicitante/inicio');
      }
      else if(role === "investor"){
        if (this.ShowInvestorOnboarding) {
          this.$router.replace({name: 'inversionistaOnboarding'});
        } else {
          this.$router.replace('inversionista/inicio');
        }
      }
      else if(role === "supplier" || role === "supplier_agent"){
        this.$router.replace('proveedor/inicio');
      } else {
        this.$router.replace('programa/inicio');
      }
    }
    else {
      this.$vs.loading();
      this.redirectToLogin();
    }
  },
  computed: {
    ...mapState("auth", ["user", "isLogged"]),
    ...mapState("extended_auth", ["has_extended_session", "extended_session_role"]),
  },
  watch: {
    isLogged(val){
      if(val){
        // esperar a que se obtengan los datos de usuario para ir al inicio
        this.$router.replace({name: 'home'});
        this.$vs.loading.close();
      }
    }
  }
};
</script>